<template>
	<div id="nosotros-container">
		<div id="title-n-container"><span>NOSOTROS</span></div>
		<div id="n-root-container">
			<div
				v-if="data === null"
				id="container-items"
			>
				<div
					v-for="item in items"
					v-bind:key = "item.title"
					v-on:click="onMoveItem(item)"
					class="item"
					v-bind:style=" 'background:'+item.color "
				>
					<span class="item-title">{{ item.title }}</span>
				</div>
			</div>
	
			<div
				v-else
				id="item-data-preview"
			>
				<div v-on:click="onBack" style="width: 100%;">
					<div v-if="data.children" id="valores-container">
						<div
							v-for="item in data.children"
							v-bind:key = "item.description"
							class="valores-container-column"
						>
							<div
								v-for="row in item"
								v-bind:key = "row.description"
								class="valores-container-row"
								v-bind:style=" 'background:'+row.color "
							>
								<div>{{row.description}}</div>
								<div
									class="icon-container"
									v-bind:style="'background-image: url(' + row.icon + ');'"
								/>
							</div>
						</div>
					</div>
	
					<div
						v-else
						class="data-container"
						v-bind:style=" 'background:'+data.color "
					>
						{{ data.description }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	export default {
		name:'nosotros-component',
		data() {
			return{
				items:[
					{
						title:'MISIÓN',
						color:'rgba(88, 202, 234, 0.82);',
						description:'Crear herramientas tecnológicas innovadoras de alto impacto para la solución de problematicas sociales a micro y gran escala.',
					},{
						title:'VISIÓN',
						color:'rgba(90, 122, 237, 0.82);',
						description:'Integrar nuestras herramientas tecnológicas eficientemente para el desarrollo integral de la población.',
					},{
						title:'VALORES',
						color:'rgba(161, 146, 255, 0.7);',
						children:[
							[
								{ description:'Liderazgo y autoliderazgo.',color:'rgba(161, 146, 255, 0.85);',icon:require('@/assets/icons/Vector-5.png') },
								{ description:'Dinamismo y flexibilidad.',color:'rgba(161, 146, 255, 0.6);',icon:require('@/assets/icons/Vector-4.png') },
								{ description:'Responsabilidad con el medio ambiente.',color:'rgba(161, 146, 255, 0.4);',icon:require('@/assets/icons/ph_tree-fill.png') },
							],[
								{ description:'Sinceridad, honestidad y transparencia.',color:'rgba(161, 146, 255, 0.85);',icon:require('@/assets/icons/Vector-2.png') },
								{ description:'Confianza en uno mismo y el equipo.',color:'rgba(161, 146, 255, 0.6);',icon:require('@/assets/icons/Vector-1.png') },
								{ description:'Espíritu colaborativo.',color:'rgba(161, 146, 255, 0.4);',icon:require('@/assets/icons/Vector-3.png') },
							],[
								{ description:'Conciencia ante los errores.',color:'rgba(161, 146, 255, 0.85);',icon:require('@/assets/icons/Vector.png') },
								{ description:'Gratitud y humildad.',color:'rgba(161, 146, 255, 0.6);',icon:require('@/assets/icons/mdi_love.png') },
								{ description:'Empatía y paciencia.',color:'rgba(161, 146, 255, 0.4);',icon:require('@/assets/icons/mdi_head-love.png') },
							],
						]
					}
				],
				data:null
			}
		},
		methods:{
			onMoveItem(i) {
				this.data = i
			},
			onBack() {
				this.data = null
			}
		}
	}
</script>


<style scoped>

	#container-items {
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		gap: 20px;
		overflow-x: auto;
		overflow-y: hidden;
		width: 600px;
	}
	.item {
		background: rgba(88, 202, 234, 0.82);
		cursor: pointer;
		border-radius: 0px;
		padding-left: 20px;
		padding-right: 20px;
		border-radius: 15px;
		height: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
	}

	#item-data-preview{
		display: flex;
		justify-content: center;
		cursor: pointer;
		overflow-x: auto;
	}

	#n-root-container {
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
	}

	#valores-container{
		display: flex;
		flex-direction: column;
		gap: 5px;
		padding-bottom: 1rem;
	}

	.valores-container-column{
		display: flex;
		flex-direction: column;
		gap: 10px;
		justify-content: space-between;
		margin: 0 2rem;
		box-sizing: border-box;

	}

	.valores-container-row {
		height: 75px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		align-content: center;
		padding-left: 20px;
		border-radius: 15px;
	}

	.item-title {
		font-style: normal;
		font-weight: 700;
		font-size: 35px;
	}

	.data-container{
		padding: 20px;
		padding-left: 55px;
		padding-right: 55px;
		text-align: center;

		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		border-radius: 20px;

		margin: 0 2rem;
	}

	.icon-container{
		width: 40px;
		height: 40px;
		margin-right: 10px;
		background-image: url('../assets/icons/mdi_head-love.png');
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}

	::-webkit-scrollbar {
		background-color: white;
		opacity: 0;
	}

	#nosotros-container{
		min-height: 100vh;
		background-image: url('../assets/bg.png'),linear-gradient(180deg, #FFFFFF 54.8%, rgba(90, 122, 237, 0.74) 97.05%);
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	#title-n-container{
		display: flex;
		justify-content: center;
		margin-bottom: 100px;
		padding-top: 65px;
	}

	#title-n-container span {
		font-style: normal;
		font-weight: 600;
		font-size: 30px;
	}


	@media (max-width: 590px) {
		#container-items{
			flex-direction: column;
		}

		#container-items{
			width: auto;
		}

		.item{
			width: 100%;
		}
	}

	@media (min-width: 1024px) {
		.data-container {
			margin: 0;
		}

		#valores-container{
			width: 1000px;
		}

		.valores-container-column{
			flex-direction: row;
		}

		.valores-container-row {
			width: 500px;			
		}
	}

</style>